<template>
  <div class="flex flex-row gap-2 items-center cursor-pointer" @click="toggle">
    <label :class="['radio-group-item-label', value ? 'on' : 'off']">
      {{ value ? 'On' : 'Off' }}
    </label>
    <input
      :checked="value"
      class="radio-input"
      type="checkbox"
      :name="`${label} -radio-input`"
      disabled
    />
    <div class="flex flex-col gap-1">
      <h6 class="text-sm font-medium">{{ label }}</h6>
      <p class="text-sm text-gray-500">{{ subtitle }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    toggle() {
      if (this.disabled) return;

      const newValue = !this.value;
      this.$emit('change', newValue); // Emit the change event with the new value
    },
  },
};
</script>

<style scoped>
.radio-group-item-label {
  display: inline-flex;
  align-items: center;
  padding: 8px 20px;
  border-radius: 12px;
  font-weight: bold;
  font-size: 16px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  box-shadow:
    0 4px 6px rgba(0, 0, 0, 0.1),
    0 1px 3px rgba(0, 0, 0, 0.08) inset,
    0 6px 16px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  color: white;
}

.radio-group-item-label.on {
  background: linear-gradient(
    145deg,
    rgba(0, 201, 123, 0.6),
    rgba(0, 155, 100, 0.4)
  );
}

.radio-group-item-label.off {
  background: linear-gradient(
    145deg,
    rgba(255, 67, 54, 0.6),
    rgba(213, 47, 47, 0.4)
  );
}

@media (prefers-color-scheme: dark) {
  .radio-group-item-label {
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow:
      0 4px 6px rgba(0, 0, 0, 0.2),
      0 1px 3px rgba(0, 0, 0, 0.12) inset,
      0 6px 16px rgba(0, 0, 0, 0.4);
  }

  .radio-group-item-label.on {
    background: linear-gradient(
      145deg,
      rgba(0, 221, 143, 0.7),
      rgba(0, 175, 120, 0.5)
    );
  }

  .radio-group-item-label.off {
    background: linear-gradient(
      145deg,
      rgba(255, 87, 74, 0.7),
      rgba(233, 57, 57, 0.5)
    );
  }
}

.radio-input {
  margin-left: 10px; /* Space between the text and the input */
  transform: scale(1.3); /* Larger radio button */
  cursor: not-allowed; /* Disabled cursor effect */
  pointer-events: none; /* Ensures radio button inside the component is truly disabled */
}
.radio-input {
  display: none;
}
div:hover {
  background-color: #f3f4f6;
}
div:focus {
  outline: 2px solid #93c5fd;
  outline-offset: 2px;
}
</style>
