import { render, staticRenderFns } from "./VariableList.vue?vue&type=template&id=15b83b6a&scoped=true"
import script from "./VariableList.vue?vue&type=script&lang=js"
export * from "./VariableList.vue?vue&type=script&lang=js"
import style0 from "./VariableList.vue?vue&type=style&index=0&id=15b83b6a&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_babel-core@7.0.0-bridge.0_@babel+core@7.13.16__cs_wccn2hqrpfdxhpsieji5st2oqu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15b83b6a",
  null
  
)

export default component.exports