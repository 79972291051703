<template>
  <form
    class="space-y-6 w-[65%] flex-shrink-0 flex-grow-0 max-w-[65%] overflow-y-auto h-[75%]"
    @submit.prevent="createChannel()"
  >
    <!-- WhatsApp Inbox Name -->
    <div class="form-group">
      <label :class="{ 'text-red-500': $v.inboxName.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.LABEL') }}
        <input
          v-model.trim="inboxName"
          class="form-input"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.PLACEHOLDER')"
          @blur="$v.inboxName.$touch"
        />
        <span v-if="$v.inboxName.$error" class="text-sm text-red-500">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.INBOX_NAME.ERROR') }}
        </span>
      </label>
    </div>

    <!-- Phone Number -->
    <div class="form-group">
      <label :class="{ 'text-red-500': $v.phoneNumber.$error }">
        {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.LABEL') }}
        <input
          v-model.trim="phoneNumber"
          class="form-input"
          type="text"
          :placeholder="$t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.PLACEHOLDER')"
          @blur="$v.phoneNumber.$touch"
        />
        <span v-if="$v.phoneNumber.$error" class="text-sm text-red-500">
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.PHONE_NUMBER.ERROR') }}
        </span>
      </label>
    </div>

    <!-- Toggles -->
    <div class="space-y-4">
      <ToggleSwitch
        :value="reopenConversations"
        :label="$t('INBOX_MGMT.ADD.WHATSAPP.REOPEN_CONVERSATIONS.LABEL')"
        :subtitle="$t('INBOX_MGMT.ADD.WHATSAPP.REOPEN_CONVERSATIONS.SUBTITLE')"
        @change="reopenConversations = $event"
      />
      <ToggleSwitch
        :value="importContacts"
        :label="$t('INBOX_MGMT.ADD.WHATSAPP.IMPORT_CONTACTS.LABEL')"
        :subtitle="$t('INBOX_MGMT.ADD.WHATSAPP.IMPORT_CONTACTS.SUBTITLE')"
        @change="importContacts = $event"
      />

      <ToggleSwitch
        :value="importMessages"
        :label="$t('INBOX_MGMT.ADD.WHATSAPP.IMPORT_MESSAGES.LABEL')"
        :subtitle="$t('INBOX_MGMT.ADD.WHATSAPP.IMPORT_MESSAGES.SUBTITLE')"
        @change="importMessages = $event"
      />
      <!-- Conditionally Rendered Field -->
      <div v-if="importMessages" class="form-group">
        <label>
          {{ $t('INBOX_MGMT.ADD.WHATSAPP.IMPORT_MESSAGES_AGE.LABEL') }}
          <input
            v-model.trim="importMessagesAge"
            class="form-input"
            type="text"
            :placeholder="$t('IMPORT_MESSAGES_AGE.PLACEHOLDER')"
          />
          <span class="text-sm text-gray-500">
            {{ $t('INBOX_MGMT.ADD.WHATSAPP.IMPORT_MESSAGES_AGE.SUBTITLE') }}
          </span>
        </label>
      </div>

      <ToggleSwitch
        :value="signMessages"
        :label="$t('INBOX_MGMT.ADD.WHATSAPP.SIGN_MESSAGES.LABEL')"
        :subtitle="$t('INBOX_MGMT.ADD.WHATSAPP.SIGN_MESSAGES.SUBTITLE')"
        @change="toggleSignMessages"
      />
    </div>

    <!-- Submit Button -->
    <div>
      <woot-submit-button
        :loading="uiFlags.isCreating"
        :button-text="$t('INBOX_MGMT.ADD.WHATSAPP.SUBMIT_BUTTON')"
      />
    </div>
  </form>
</template>

<script>
import { mapGetters } from 'vuex';
import alertMixin from 'shared/mixins/alertMixin';
import { required } from 'vuelidate/lib/validators';
import router from '../../../../index';

import { isPhoneE164OrEmpty } from 'shared/helpers/Validators';
import ToggleSwitch from 'dashboard/routes/dashboard/settings/inbox/components/ToggleSwitch.vue';

export default {
  components: { ToggleSwitch },
  mixins: [alertMixin],
  data() {
    return {
      inboxName: '',
      phoneNumber: '',
      apiKey: '',
      pendingConversations: false,
      reopenConversations: false,
      importContacts: false,
      importMessages: false,
      importMessagesAge: '7',
      signMessages: false,
    };
  },
  computed: {
    ...mapGetters({ uiFlags: 'inboxes/getUIFlags' }),
  },
  validations: {
    inboxName: { required },
    phoneNumber: { required, isPhoneE164OrEmpty },
  },
  methods: {
    async createChannel() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }

      try {
        const whatsappChannel = await this.$store.dispatch(
          'inboxes/createNativeWhatsappChannel',
          {
            name: this.inboxName,
            channel: {
              type: 'ruut_native_whatsapp',
              phone_number: this.phoneNumber,
              settings: {
                pendingConversations: this.pendingConversations,
                reopenConversations: this.reopenConversations,
                importContacts: this.importContacts,
                importMessages: this.importMessages,
                importMessagesAge: this.importMessagesAge,
                signMessages: this.signMessages,
              },
            },
          }
        );

        router.replace({
          name: 'settings_inboxes_add_agents',
          params: {
            page: 'new',
            inbox_id: whatsappChannel.id,
          },
        });
      } catch (error) {
        this.showAlert(
          error.message || this.$t('INBOX_MGMT.ADD.WHATSAPP.API.ERROR_MESSAGE')
        );
      }
    },
    toggleSignMessages() {
      this.signMessages = !this.signMessages;
    },
  },
};
</script>
