import { render, staticRenderFns } from "./CalendarFooter.vue?vue&type=template&id=ee2ed4c8"
import script from "./CalendarFooter.vue?vue&type=script&setup=true&lang=js"
export * from "./CalendarFooter.vue?vue&type=script&setup=true&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/.pnpm/vue-loader@15.11.1_@vue+compiler-sfc@3.5.13_babel-core@7.0.0-bridge.0_@babel+core@7.13.16__cs_wccn2hqrpfdxhpsieji5st2oqu/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports